import React from 'react'
import SEO from '../Seo'

import {
  BlogContainer,
  BlogSingleProject,
  SingleProjectDate,
  SingleProjectExcerpt,
  SingleProjectLink,
  SingleProjectTitle,
  SingleProjectInfo,
  SingleAuthor,
} from './styles'

const BlogLists = (props) => {
  const posts = props.data.map((project) => {
    const frontmatter = project.node.frontmatter
    const title = frontmatter.title
    const urlToProject = frontmatter.path
    const date = frontmatter.date
    const excerpt = frontmatter.excerpt
    const author = frontmatter.author

    return (
      <BlogSingleProject key={title}>
        <SEO title={'Blog'} />
        <SingleProjectLink to={`/blog${urlToProject}`}>
          <SingleAuthor>
            {author}
            <SingleProjectDate>{date}</SingleProjectDate>
          </SingleAuthor>
          <SingleProjectInfo>
            <SingleProjectTitle>{title}</SingleProjectTitle>
            <SingleProjectExcerpt>{excerpt}</SingleProjectExcerpt>
          </SingleProjectInfo>
        </SingleProjectLink>
      </BlogSingleProject>
    )
  })

  return (
    <>
      <BlogContainer>{posts}</BlogContainer>
    </>
  )
}

export default BlogLists
