import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import {
  largeBreakpoint,
  fullhd,
  mediumBreakpoint,
} from '../../config/css-constants'

export const BlogContainer = styled.section`
  display: grid;
  padding: 0 50px;
  grid-column-gap: 30px;
  grid-row-gap: 50px;
  @media all and (min-width: ${mediumBreakpoint}) {
    grid-template-columns: 1fr 1fr;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    padding: 50px 200px;
  }
  @media all and (min-width: ${fullhd}) {
    padding: 100px 200px;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 90%;
    margin: 0 auto;
  }
`

export const BlogSingleProject = styled.div`
  box-shadow: 0px 1px 2px rgba(46, 41, 51, 0.08),
    0px 2px 4px rgba(71, 63, 79, 0.08);
  background: #ffffff;
  border-radius: 4px;
  border: 0;
`

export const SingleProjectLink = styled((props) => <Link {...props} />)`
  height: 100%;
  color: #000;
  padding: 0 0 50px;
  display: block;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0px 4px 8px rgba(46, 41, 51, 0.08),
      0px 8px 16px rgba(71, 63, 79, 0.16);
  }
`

export const SingleProjectTitle = styled.h2`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`

export const SingleProjectDate = styled.span`
  font-size: 0.9rem;
  font-weight: 300;
`

export const SingleProjectExcerpt = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`
export const SingleAuthor = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  color: #fff;
  background-color: #357e8d;
  padding: 10px 30px;
`

export const SingleProjectInfo = styled.article`
  padding: 20px 30px;
`
