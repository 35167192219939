import React from 'react'
import { graphql } from 'gatsby'
import BlogLists from 'components/Blog/BlogLists'
import { Pagination, PaginationLink } from './blogListstyles'

const BlogList = (props) => {
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? '/blog/' : '/blog/' + (currentPage - 1).toString()
  const nextPage = '/blog/' + (currentPage + 1).toString()
  const edges = props.data.allMarkdownRemark.edges

  return (
    <>
      <BlogLists data={edges} />
      <Pagination style={{ textAlign: 'center' }}>
        {!isFirst && (
          <PaginationLink to={prevPage} rel="prev">
            Previous Page
          </PaginationLink>
        )}
        {!isLast && (
          <PaginationLink to={nextPage} rel="next">
            Next Page
          </PaginationLink>
        )}
      </Pagination>
    </>
  )
}

export const query = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } } }
      limit: $limit
      skip: $skip
      sort: { fields: [frontmatter___date, frontmatter___title], order: DESC }
    ) {
      edges {
        node {
          fields {
            collection
          }
          frontmatter {
            title
            path
            date
            excerpt
            author
          }
        }
      }
    }
  }
`

export default BlogList
