import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export const Pagination = styled.div`
  text-align: center;
  margin-top: 20px;
`

export const PaginationLink = styled((props) => <Link {...props} />)`
  background-color: unset;
  border: 2px solid #357e8d;
  color: #357e8d;
  padding: 10px 20px;
  border-radius: 25px;
  display: inline-block;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;

  transition: all 0.4s ease;
  min-width: 150px;
  &:hover {
    color: #fff;
    border: 2px solid #357e8d;
    background-color: #357e8d;
  }
`
